window.cookieInfoHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    closeCookieInfo();
  });

  const closeCookieInfo = () => {
    const closeButton = document.querySelector('.jsCookieClose');
    const cookieInfo = closeButton.closest('.jsCookieInfo');

    closeButton.addEventListener('click', (e) => {
      e.preventDefault();

      // Set cookie
      setCookie('cookies_accepted', 1, 365);
      // Hide cookie info
      cookieInfo.classList.add('hidden');
    });
  };
};

